<template>
  <div class="grid grid-cols-12 gap-6">
    <!-- BEGIN: Menu -->
    <UserCard :user="user_data" :profile="profile" />
    <!-- END: Menu -->

    <div class="col-span-12 lg:col-span-8 xxl:col-span-9">
      <router-view></router-view>
    </div>

  </div>
</template>

<script>
import UserCard from '@/components/users/UserCard'
import BankInfo from '@/components/account/profile/bank'
import ProfileInfo from '@/components/account/profile/profile'
import LoginInfo from '@/components/account/profile/login'

import { RepositoryFactory } from '@/services/repositories/AuthAPI/RepositoryFactory'
const UsersRepo = RepositoryFactory.get('user')
const ProfileRepo = RepositoryFactory.get('profile')
const BankRepo = RepositoryFactory.get('bank')

export default {
    name: 'Profile',
    components: {
      UserCard,
      ProfileInfo,
      BankInfo,
      LoginInfo
    },
    props: {
      user: {
        required: true,
        type: Number | String
      }
    },
    data() {
      return {
        profile: {},
        bank_info: {},
        user_data: {},
        isLoaded: false
      }
    },
    created() {
        this.getUserInfo(this.user)
        this.getProfileInfo(this.user)
        this.getBankInfo(this.user)
    },
    methods: {
        getUserInfo: function(id) {
          UsersRepo.get(id).then(result => {
            this.user_data = result.data
          }).catch(err => {})
        },
        getProfileInfo: function(id) {
          ProfileRepo.get(id).then(result => {
            this.profile = result.data
          }).catch(err => {})
        },
        getBankInfo: function(id) {
          BankRepo.get(id).then(result => {
            this.bank_info = result.data
          }).catch(err => {})
        },
        activateUser: function() {
            // activate user
        },
        suspendUser: function() {
            // suspend user
        },
        verifyAccount: function() {
            // send verification message to user
        }
    },
    computed: {
    },
    watch: {
        '$route.params.id': function (id) {
            if(typeof id !== 'undefined') {
                this.getUserInfo(id)
                this.getProfileInfo(id)
                this.getBankInfo(id)
            }
        }
    },
}
</script>

<style scoped>

</style>