<template>
    <div  class="intro-y box mt-8">
        <div class="">
            <div class="flex flex-row items-center justify-between p-5 border-b border-gray-200">
                <h4 class="font-medium text-base mr-auto">{{ website.name }} Shares </h4>
                <div class="">
                    <button class="btn btn-tool btn-sm" @click.prevent="reset_view()"> <fa-icon :icon="['fa', 'plus']"></fa-icon></button> 
                </div>
            </div>
            <div class="p-5">
                <div class="flex flex-col xl:flex-row justify-between border-b border-gray-200 pb-5">
                    <div class="flex flex-col mb-4 xl:mb-0 w-12/12  xl:w-7/12">
                        <label class="font-medium text-base" for="name">Group:</label>
                        <select id="group_type" name="group_type" class="border border-gray-300" v-model="share.group_id">
                            <option class="" v-for="group in groups" :key="group.id" :value="group.id"> {{ group.name | capitalize }}</option>
                        </select>
                    </div>
                    <div class="flex flex-col w-12/12  xl:w-2/12 mb-4 xl:mb-0">
                        <label class="font-medium text-base " for="name">Share: (%)</label>
                        <input type="text" id="name" class=" intro-x login__input input input--md border border-gray-300 block text-center" name="share" required v-model="share.share" >
                    </div>

                    <div class="self-start  md:self-start xl:self-end xl-w-2/12  mb-4 xl:mb-0">
                        <button type="button" @click="submit()"
                        class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-10  rounded" >Save</button>
                    </div>
                </div>
            </div>
            
            <div class="p-5">
                <ul class="custom-select-list">
                    <li v-for="share in shares" :key="share.group_id">
                        {{ GroupTypeName(share.group_id) }} - {{ share.share }}%
                        <a href="#" @click.prevent="edit(share)" class="float-right info-btn">Edit</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { RepositoryFactory } from '@/services/repositories/NetworkAPI/RepositoryFactory'
const GroupsTypesRepo = RepositoryFactory.get('groups_types')
const SharesRepo = RepositoryFactory.get('shares')

export default {
    props: {
        website: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            share: {
                share: 0,
                group_id: 0,
                website_id: this.website.id,
                network_id: this.$store.getters.user.id,
            },
            groups: [],
            shares: [],
            message: ""
        }
    },
    mounted: function() {
        var self = this
        $('#group_type').on("change", function(e) { 
            self.share.group_id = $(this).val()
        });
    },
    created() {
        this.loadGroupsTypes()
        this.loadWebsiteShares()
    },
    methods: {
        loadGroupsTypes: function() {
            GroupsTypesRepo.get().then(result => {
                this.groups = result.data.results
            }).catch(err => {

            })
        },
        loadWebsiteShares: function() {
            SharesRepo.get(this.website.id).then(result => {
                this.shares = result.data.results
            }).catch(err => {

            })
        },
        submit: function() {
            if(this.share.id) {
                this.update()
            } else {
                this.add()
            }
        },
        add: function() {
            SharesRepo.post(this.share).then(result => {
                this.shares.push(this.share)
                this.share = {}
                this.$notify({ 
                    group: 'ops',
                    type: 'success',
                    text: 'Share added successfully' 
                })
            }).catch(err => {
          this.$notify({ 
              group: 'ops',
              type: 'error',
              title: 'Faild to add share',
              text: err.response.data
          })
            })
        },
        edit: function(share) {
            this.share = share
        },
        update: function() {
            SharesRepo.patch(this.share.id, this.share).then(result => {
                this.shares.pop(this.share)
                this.shares.push(this.share)
                this.share = {}
                this.$notify({ 
                    group: 'ops',
                    type: 'success',
                    text: 'Share updated successfully' 
                })
            }).catch(err => {
                this.$notify({ 
                    group: 'ops',
                    type: 'error',
                    title: 'Faild to update share',
                    text: err.response.data
                })
            })
        },
        remove: function(id) {
            SharesRepo.delete(id).then(result => {
                this.shares.pop(this.share)
                this.share = {}
                this.$notify({ 
                    group: 'ops',
                    type: 'success',
                    text: 'Share deleted successfully' 
                })
            }).catch(err => {
                this.$notify({ 
                    group: 'ops',
                    type: 'error',
                    title: 'Faild to delete share',
                    text: err.response.data
                })
            })
        },
        GroupTypeName: function(group_id) {
            var group = this.groups.filter(element => { return element.id == group_id })[0]
            if(group)
                return group.name
        },
    },
    computed: {
    },
}
</script>

<style scoped>  
    select {
        padding: 10px;
    }
    .custom-select-list {
        list-style-type: none;
        text-align: left;
        margin: 5px 0 0 0;
        padding: 10px;
        border: 1px #CCCCCC solid;
        height: 300px;
        overflow-y: scroll;
        border-radius: 2px;
    }
    .custom-select-list li:first-child {
        padding: 15px;
    }
    .custom-select-list li {
        display: block;
        padding: 15px;
        min-height: 45px;
        border-bottom: 1px #f2f2f2 solid;
    }
    .custom-select-list li a {
        display: block;
        padding: 5px;
        min-height: 10px;
        border-left: 1px solid #ccc;
    }
    .custom-select-list li a:last-child {
        border-left: 0px;
    }
    .custom-select-list .remove-btn {
        color: #ad3737;
    }

</style>