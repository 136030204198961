import Repository from './Repository';

const resource = "/shares";
export default {
    get(website_id) {
        return Repository.get(`${resource}/network/?website=${website_id}`);
    },
    post(data) {
        return Repository.post(`${resource}/network/`, data);
    },
    patch(id, data) {
        return Repository.put(`${resource}/network/${id}/`, data);

    },
    delete(id) {
        return Repository.delete(`${resource}/network/${id}/`);

    }
}