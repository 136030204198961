<template>
    <!--Modal view-->
    <div class="modal " id="header-footer-modal-preview">
        <div class="modal__content mt-32">
            <div class="chat__chat-list  overflow-y-auto scrollbar-hidden pr-1 pt-1 mt-4">
                <div class="intro-x cursor-pointer box relative flex items-center p-5 ">
                    
                    <div class="ml-2 overflow-hidden">
                        <div class="flex items-center">
                            <a href="javascript:;" class="font-medium">{{message.name}}</a> 
                            <div class="text-xs text-gray-500 ml-auto">01:10 PM</div>
                        </div>
                        <div class="w-full text-gray-600">{{message.content}}</div>
                    </div>
                </div>
            </div>    
        </div>
    </div>
    <!--end of modal view-->
</template>
<script>

export default {
    data() {
        return{
            messages: [],
            message: {}
        }
    },
    mounted() {
        this.message = messages.filter(message=> message.id == this.$route.params.id)[0]
    },
    watch: {
        '$route.params.id':function(){
          this.message = messages.filter(message=> message.id == this.$route.params.id)[0]
        }
    }
}
</script>

<style scoped>

</style>