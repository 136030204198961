<template>
    <div class="-intro-x breadcrumb mr-auto hidden sm:flex">
      <router-link :to="{name: 'home'}">Home</router-link>
      <template v-for="(path, index) in crumbs">
        <fa-icon icon="chevron-right" size="xs" class="breadcrumb__icon" />
        <router-link :to="{path: path.to}">{{ path.text | capitalizeAndReplace }}</router-link>
      </template>
      </div>
</template>
<script>
export default {
    name: 'Breadcrumb',
    mounted () {
      this.$feather.replace()
    },
    methods: {
      toName: function() {
        // if is on websites page
        // if is numeric return name

        // if is on users page
        // if is numeric return name
      }
    },
    computed:{
      pageNameRoute:function(){
        return this.$route.name
      },
      crumbs: function() {
        let pathArray = this.$route.path.split("/")
        pathArray.shift()
        let breadcrumbs = pathArray.reduce((breadcrumbArray, path, idx) => {
          breadcrumbArray.push({
            path: path,
            to: breadcrumbArray[idx - 1]
              ? "/" + breadcrumbArray[idx - 1].path + "/" + path
              : "/" + path,
            text: this.$route.matched[idx].meta.breadCrumb || path,
          });
          return breadcrumbArray;
        }, [])
        return breadcrumbs;
      }
    },
}
</script>

<style scoped>

</style>