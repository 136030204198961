<template>
  <div class="grid grid-cols-12 gap-6">
      <!-- BEGIN: Menu -->
      <UserCard :user="user" />
      <!-- END: Menu -->
      <div class="col-span-12 lg:col-span-8 xxl:col-span-9">
        <router-view></router-view>
      </div>
  </div>
</template>

<script>
import UserCard from '@/components/account/profile/UserCard'

export default {
    name: 'Profile',
    components: {
      UserCard,
    },
    data() {
      return {
        profile: null,
        bank_info: null
      }
    },
    created() {
    },
    mounted() {
      this.$feather.replace()
    },
    methods: {
    },
    computed: {
      user: function() {
        return this.$store.getters.user
      },
    }
}
</script>

<style scoped>

</style>