import Repository from './Repository';

const resource = "/auth/assigned";
export default {
    page(page=1, limit=10, search='') {
        var offset = (page - 1) * limit
        if(search) { search = `&search=${search}` }
        return Repository.get(`${resource}/?limit=${limit}&offset=${offset}${search}`);
    },
    get(id) {
        return Repository.get(`${resource}/${id}/`);
    },
    add(data) {
        return Repository.post(`${resource}/`, data);
    },
    update(id, data) {
        return Repository.patch(`${resource}/${id}/`, data);
    },
    delete(id) {
        return Repository.delete(`${resource}/${id}/`);
    },
    filter(id, role) {
        return Repository.get(`${resource}/?${role}=${id}`);
    },
}