<template>
 <!-- BEGIN: Profile Menu -->
    <div class="col-span-12 lg:col-span-4 xxl:col-span-3 flex lg:block flex-col-reverse">
        <div class="intro-y box mt-5">
            <div class="relative flex items-center p-5">
                <div class="w-12 h-12 image-fit">
                    <input type="file" ref="file" @change="onChangeFileUpload" style="display: none;" />
                    <img alt="User" class="rounded-full cursor-pointer zoom-in" :src="user.profile_image || imagePath" @click="$refs.file.click()">
                </div>
                <div class="ml-4 mr-auto">
                    <div class="font-medium text-base">{{ user.first_name }} {{ user.last_name }}</div>
                    <div class="text-gray-600">{{ user.role | capitalizeAndReplace }}</div>
                </div>
            </div>
            <div class="p-5 border-t border-gray-200 dark:border-dark-5">
                <router-link :to="{name: 'profile-info', params: {id: user.id}}" exact-active-class="text-theme-1 font-medium" class="flex items-center"> <i data-feather="activity" class="w-4 h-4 mr-2"></i> Personal Information </router-link>
                <router-link :to="{name: 'bank', params: {id: user.id}}" exact-active-class="text-theme-1 font-medium" class="flex items-center mt-5"> <i data-feather="box" class="w-4 h-4 mr-2"></i> Bank Account</router-link>
                <router-link :to="{name: 'credentials', params: {id: user.id}}" exact-active-class="text-theme-1 font-medium" class="flex items-center mt-5"> <i data-feather="lock" class="w-4 h-4 mr-2"></i> Change Login </router-link>
            </div>
        </div>
    </div>
    <!-- END: Profile Menu -->
</template>
<script>
import { RepositoryFactory } from '@/services/repositories/AuthAPI/RepositoryFactory';
const ProfileRepo = RepositoryFactory.get('profile');

export default {
    props: {
        user: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            selectedFile: null,
            imagePath: '/assets/images/profile-7.jpg',
        }
    },
    created() {
      this.$feather.replace()
    },
    mounted() {
      this.$feather.replace()
    },
    methods: {
        onUpload() {
            let formData = new FormData();
            formData.append('image', this.selectedFile, this.selectedFile.name);
            ProfileRepo.upload(this.user.id, formData).then(result => {
                console.log(result)
            }).catch(err => {
                console.log(err)
            })
        },
        onChangeFileUpload() {
            this.selectedFile = this.$refs.file.files[0];
            this.imagePath = URL.createObjectURL(this.selectedFile);
            this.$store.commit('PROFILE_IMAGE', this.imagePath)
            this.onUpload()
        },
        onUploadProgress() {
            
        },
    }
}
</script>
<style scoped>

</style>