<template>
<!-- BEGIN: Top Bar -->
<div class="top-bar">
    <!-- BEGIN: Breadcrumb -->
    <Breadcrumb />
    <!-- END: Breadcrumb -->
    <!-- BEGIN: Search -->
    <div class="intro-x relative mr-3 sm:mr-6">
        <a class="notification sm:hidden" href="#"> <i data-feather="search" class="notification__icon"></i> </a>
    </div>
    <!-- END: Search -->
    <!--user data-->
           <!-- BEGIN: User ID -->
    <div class="intro-x mr-auto hidden sm:block sm:mr-6">
        <div class=""> 
          {{ userFullName }} | {{ user.role | capitalizeAndReplace }}<br /> 
          <!-- <small>{{ user.role | capitalizeAndReplace }} ID: {{ user.id }}</small> -->
        </div>
    </div>
    <!-- END:  User ID -->
    <!-- BEGIN: Notifications -->
    <div class="intro-x dropdown relative mr-6">
        <div class="dropdown-toggle notification cursor-pointer"> <i data-feather="bell" class="notification__icon"></i> </div>
        <div class="notification-content dropdown-box mt-8 absolute top-0 left-0 sm:left-auto sm:right-0 z-20 sm:ml-0 shadow-xl">
            <div class="notification-content__box  dropdown-box__content box">
              <div class="flex flex-row justify-between border-b dark:border-gray-700">
                <div class="notification-content__title ">Notifications</div>
                <div class="notification-content__title  mr-5 cursor-pointer text-theme-1 dark:text-theme-16">Clear All</div>
              </div>
              <!-- <div class="h-56 overflow-auto scrolling-touch my-2">
                <router-link :to="{name:'message' , params:{id:message.id} }" v-for="message in messages" :key="message.id" class="cursor-pointer dropdown-toggle relative py-2 flex items-center " data-toggle="modal" data-target="#header-footer-modal-preview">
                  <div class="ml-2 overflow-hidden">
                      <div class="flex items-center">
                          <a href="javascript:;" class="font-medium truncate  mr-5">{{message.name}}</a> 
                          <div class="text-xs  ml-auto whitespace-no-wrap">05:09 AM</div>
                      </div>
                      <div class="w-full truncate text-gray-600">{{ message.content }}</div>
                  </div>
                </router-link>
              </div> -->
              <router-link :to="{name:'notification' }" class="font-medium text-base text-center block  dropdown-toggle px-2  pt-2 rounded-lg border-t dark:border-gray-700">View All</router-link>
            </div>
        </div>
    </div>
    <!-- END: Notifications -->
            <!-- BEGIN: User ID -->
    <div class="intro-x  ml-auto block sm:hidden">
        <div class=""> 
          {{ userFullName }} | {{ user.role | capitalizeAndReplace }}<br /> 
          <small>{{ user.role | capitalizeAndReplace }} ID: {{ user.id }}</small>
        </div>
    </div>
    <!-- END:  User ID -->
    <!-- BEGIN: Account Menu -->
    <div class="intro-x dropdown w-8 h-8 relative ml-4">
        <div class="dropdown-toggle w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in">
            <img alt="" :src="user.profile_image || '/assets/images/profile-7.jpg'">
        </div>
        <div class="dropdown-box mt-10 absolute w-56 top-0 right-0 z-20 shadow-xl ">
            <div class="dropdown-box__content box" >
              <div class="p-4 border-b dark:border-gray-700">
                  <div class="font-medium">{{ userFullName }}</div>
                  <div class="text-xs text-gray-500">{{ user.role | capitalizeAndReplace }}</div>
              </div>
              <div class="p-2">
                  <router-link :to="{name: 'profile'}" class="dropdown-toggle flex items-center block p-2 transition duration-300 ease-in-out hover:bg-gray-500 rounded-md"> 
                      <i data-feather="user" class="w-4 h-4 mr-2 "></i> Profile
                  </router-link>
              </div>
              <!-- BEGIN: Dark Mode Switcher-->
              <div class="dark-mode-switcher p-2 border-t dark:border-gray-700">
                <div class="input--switch flex items-center p-2 w-full hover:bg-gray-500 text-gray-700 dark:text-gray-300 rounded-md transition duration-300 ease-in-out "><i data-feather="moon" class="w-4 h-4 mr-2"></i>Dark Mode
                  <input class="m-1 ml-auto input input--switch border" @click="darkModeEvent" type="checkbox" value="1">
                </div>
              </div>
              <div class="p-2 border-t dark:border-gray-700">
                  <a href="#" @click.prevent="logout()" class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-gray-500 rounded-md"> 
                      <i data-feather="toggle-right" class="w-4 h-4 mr-2"></i> Logout 
                  </a>
              </div>
            </div>
        </div>
    </div>
    <!-- END: Account Menu -->
</div>
<!-- END: Top Bar -->
</template>
<script>
import Breadcrumb from "@/components/core/Breadcrumb"

export default {
  data: function() {
    return {
      messages: []
    }
  },
  props: {
    user: {
      type: Object,
    },
  },
  components: {
    Breadcrumb
  },
    methods: {
    logout() {
      if (event) event.preventDefault()
      this.$store.dispatch('AUTH_LOGOUT').then(() => {
        this.$router.push('/login')
      })
    },
    darkModeEvent() {
      "use strict"; // Dark mode setter

      if (localStorage.getItem('dark-mode') === null) {
        $('html').addClass('dark');
        $('.dark-mode-switcher').find('input').prop('checked', true);
      } // Dark mode switcher
      else{
        $('html').removeClass('dark');
        $('.dark-mode-switcher').find('input').prop('checked', false);
      }

      $('.dark-mode-switcher').find('input').on('change', function () {
        if ($(this).is(':checked')) {
          localStorage.setItem('dark-mode', 'true');
        } else {
          localStorage.removeItem('dark-mode');
        }

      });
    }  
  }, 
  computed: {
    userFullName() {
      return this.user.first_name + " " + this.user.last_name
    },
    verifiedUser() {
      if(this.user.is_verified) {
        return 'Verified'
      } else {
        return 'Not Verified'
      }
    },
  }
}
</script>

<style scoped>

</style>