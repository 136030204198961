import Vue from 'vue'
import Vuex from 'vuex'
import VueLocalStorage from 'vue-localstorage';

Vue.use(Vuex)
Vue.use(VueLocalStorage);

var auth_header

let NETWORK = 'network'
let ADMIN = 'admin'
let DEVELOPER = 'developer'

import { RepositoryFactory } from '../../services/repositories/AuthAPI/RepositoryFactory'
const AuthRepo = RepositoryFactory.get('auth') 

const state = {
  accessToken: localStorage.getItem('accessToken'),
  refreshToken: localStorage.getItem('refreshToken'),
  is_verified: localStorage.getItem('is_verified'),
  has_permission: localStorage.getItem('has_permission'),
  session: [],
  status: '',
  user: JSON.parse(localStorage.getItem('user')),
  impersonating_user: JSON.parse(localStorage.getItem('impersonating_user')),
}

const getters = {
  user(state) {
    return state.user
  },
  isAuthenticated(state) {
    return !!state.accessToken
  },
  isImpersonated(state) {
    return !!state.impersonating_user
  },
  impersonating_user(state) {
    return state.impersonating_user
  },
  accessToken(state) {
    return state.accessToken
  },
  refreshToken(state) {
    return state.refreshToken
  },
  isVerified(state) {
    return state.is_verified
  },
  hasPermission(state){
    return state.has_permission
  },
  authStatus: state => state.status,
  displayLanguage: state => state.user.display_language
}

const mutations = {
  AUTH_REQUEST: (state) => {
    state.status = 'loading'
  },
  AUTH_SUCCESS: (state, token) => {
    state.status = 'success'
    state.accessToken = token
  },
  AUTH_EXPIRED: (state, token) => {
    state.status = 'expired'
    state.accessToken = ''
  },
  AUTH_ERROR: (state) => {
    state.status = 'error'
  },
  USER_LOGGEDIN: (state, token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  
    const data = (JSON.parse(jsonPayload))
    // decode toke and set user data
    localStorage.setItem('user', JSON.stringify(data.user) );
    state.user = JSON.parse(localStorage.getItem('user'));
  },
  USER_IMPERSONATED: (state, token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  
    const data = (JSON.parse(jsonPayload))
    // decode toke and set user data

    localStorage.setItem('impersonating_user', JSON.stringify(data.managing_user) );
    state.impersonating_user = JSON.parse(localStorage.getItem('impersonating_user'));
  },
  USER_VERIFIED: (state, action) => {
    localStorage.setItem('is_verified', action)
  },
  USER_HAS_PERM: (state, action) => {
    localStorage.setItem('has_permission', action)
  },
  USER_LOGGEDOUT: (state) => {
    state.user = {}
    state.is_verified = false
    state.has_permission = false
    state.user.profile_image = '/assets/images/profile-7.jpg'
  },
  PROFILE_IMAGE(state, action) {
    state.user.profile_image = action
    localStorage.setItem('user', JSON.stringify(state.user) );
  }
}

const actions = {
  AUTH_REQUEST: ({commit, dispatch, state}, user) => {
    return new Promise((resolve, reject) => { // The Promise used for router redirect in login
      commit('AUTH_REQUEST')
      AuthRepo.login(user).then(resp => {
          const token = resp.data.access
          const refreshToken = resp.data.refresh
          localStorage.setItem('accessToken', resp.data.access) // store the token in localstorage
          //localStorage.setItem('refreshToken', resp.data.refresh) // store the token in localstorage
          
          // you have your token, now log in your user :)
          dispatch('ADD_AUTH_HEADER')
          commit('AUTH_SUCCESS', token)
          commit('USER_LOGGEDIN', token)
          dispatch('USER_PERMISSION')
          resolve(resp)

        }).catch(err => {
          commit('AUTH_ERROR', err)
          dispatch('AUTH_LOGOUT')
          reject(err)
        })
    })
  },
  USER_PERMISSION: ({dispatch, commit}, token) => {
    let user = JSON.parse(localStorage.getItem('user'))
    if(user.role == NETWORK ||
      user.role == ADMIN ||
      user.role == DEVELOPER) {
      commit('USER_HAS_PERM', true)
      return true
    }
    else {
      commit('USER_HAS_PERM', false)
      dispatch('AUTH_LOGOUT')
      return false
    }
  },
  ADD_AUTH_HEADER: ({state}) => {
    auth_header = axios.interceptors.request.use((config) => {
      if(state.accessToken) {
          config.headers['Authorization'] = `Bearer ${state.accessToken}`
      }
      return config
    }, (err) => {
        console.log(err)
        return Promise.reject(err)
    })
  },
  VERIFY_TOKEN: ({commit, dispatch, state}, token) => {
    return new Promise((resolve, reject) => { 
      // The Promise used for router redirect in login

      if(state.accessToken === '' || state.accessToken === null) {
        dispatch('AUTH_LOGOUT')
        return
      }

      commit('AUTH_REQUEST')
      AuthRepo.verify_token({token: state.accessToken}).then(resp => {
        dispatch('USER_PERMISSION')

        if(state.has_permission) {
          commit('USER_VERIFIED', true)
        } else {
          commit('USER_VERIFIED', false)
        }

        resolve(resp)
      })
      .catch(err => {
        dispatch('AUTH_LOGOUT')
        reject(err)
      })
    })
  },
  AUTH_LOGOUT: ({commit, dispatch}) => {
    return new Promise((resolve, reject) => {
      localStorage.removeItem('accessToken') // clear your user's token from localstorage
      localStorage.removeItem('user') // clear your user's token from localstorage
      localStorage.clear() // clear your user's token from localstorage
      axios.interceptors.request.eject(auth_header);
      commit('USER_LOGGEDOUT')
      commit('AUTH_EXPIRED') // clear your user data from localstorage
      commit('USER_VERIFIED', false)
      commit('USER_HAS_PERM', false)
      resolve()  
    })
  },
  SIGNUP: ({commit, dispatch}, user) => {
    return new Promise((resolve, reject) => { // The Promise used for router redirect in login
      AuthRepo.signup(user).then(resp => {
          resolve(resp)
        })
      .catch(err => {
        reject(err)
      })
    })
  },
  VERIFY: ({commit, dispatch}, data) => {
    return new Promise((resolve, reject) => { // The Promise used for router redirect in login
      AuthRepo.verify(data).then(resp => {
        resolve(resp)
        })
      .catch(err => {
        reject(err)
      })
    })
  },
  FORGOT: ({commit, dispatch}, data) => {
    return new Promise((resolve, reject) => { // The Promise used for router redirect in login
      AuthRepo.forgot(data).then(resp => {
        resolve(resp)
        })
      .catch(err => {
        reject(err)
      })
    })
  },
  RESET: ({commit, dispatch}, data) => {
    return new Promise((resolve, reject) => { // The Promise used for router redirect in login
      AuthRepo.reset(data).then(resp => {
        resolve(resp)
        })
      .catch(err => {
        reject(err)
      })
    })
  },
  IMPERSONATION: ({commit, dispatch}, token) => {
    return new Promise((resolve, reject) => {
      // verifiy token
      AuthRepo.verify({token: token}).then(resp => {
          localStorage.setItem('accessToken', token) // store the token in localstorage
          //localStorage.setItem('refreshToken', resp.data.refresh) // store the token in localstorage
          
          // you have your token, now log in your user :)
          dispatch('ADD_AUTH_HEADER')
          commit('AUTH_SUCCESS', token)
          commit('USER_LOGGEDIN', token)
          commit('USER_IMPERSONATED', token)
          dispatch('USER_PERMISSION')  
          resolve(resp)
        })
      .catch(err => {
        localStorage.removeItem('accessToken') // clear your user's token from localstorage
        localStorage.removeItem('user') // clear your user's token from localstorage
        localStorage.clear() // clear your user's token from localstorage
        axios.interceptors.request.eject(auth_header);
        commit('USER_LOGGEDOUT')
        commit('AUTH_EXPIRED') // clear your user data from localstorage
        commit('USER_VERIFIED', false)
        commit('USER_HAS_PERM', false)
        reject(err)
      })  
    })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
