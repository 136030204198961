<template>
<div class="intro-y box lg:mt-5">
    <div v-if="editMood">
        <!--card Header-->
        <div class="flex items-center p-5 border-b  border-gray-200 dark:border-dark-5">
            <h2 class="font-medium text-lg mr-auto">
                User Credentials
            </h2>
            <div @click.prevent="editMood = false" class="outline-none text-theme-3 text-lg py-5 cursor-pointer">
                <fa-icon :icon="['fas' , 'arrow-left']" /> Back
            </div>
        </div>
        <!--card Header-->

        <!--card body top-->
        <div class="p-5">
            <div class="intro-x mt-8 px-0 sm:px-10">
                <div class="intro-x flex flex-col sm:flex-row items-center mt-5">
                    <label class="w-full sm:w-40 font-medium text-base sm:mr-5"><fa-icon :icon="['fas' , 'envelope']" /> Email</label>
                    <input type="email" v-model="email_change.email" class="input w-full border mt-2 flex-1">
                </div>
                <div class="intro-x flex flex-col sm:flex-row items-center mt-5">
                    <label class="w-full sm:w-40 font-medium text-base sm:mr-5"><fa-icon :icon="['fas' , 'check-double']" /> Confirm Email</label>
                    <input type="email" v-model="email_change.email_confirm" class="input w-full border mt-2 flex-1">
                </div>
            </div>
            <div class=" mt-8 px-0 sm:px-10">
                <button  @click.prevent="changeEmail()" class="button bg-theme-1 text-white py-2 px-8">Update</button>
            </div>
            <errors :errors="errors_email" />
            <messages :messages="messages_email" />
        </div>
        <!--card body top-->

        <div class="border-t border-gray-200 dark:border-dark-5"></div>

        <!--card body bottom-->
        <div class="p-5">
            <div class="intro-x mt-8 px-0 sm:px-10">
                <div class="intro-x flex flex-col sm:flex-row items-center mt-5">
                    <label class="w-full sm:w-48 font-medium text-base sm:mr-5"><fa-icon :icon="['fas' , 'unlock']" /> Old Password</label>
                    <input type="password" v-model="password_change.old_password" class="input w-full border mt-2 flex-1">
                </div>
                <div class="intro-x flex flex-col sm:flex-row items-center mt-5">
                    <label class="w-full sm:w-48 font-medium text-base sm:mr-5"><fa-icon :icon="['fas' , 'lock']" /> New Password</label>
                    <input type="password" v-model="password_change.new_password" class="input w-full border mt-2 flex-1">
                </div>
                <div class="intro-x flex flex-col sm:flex-row items-center mt-5">
                    <label class="w-full sm:w-48 font-medium text-base sm:mr-5"><fa-icon :icon="['fas' , 'check-double']" /> Confirm Password</label>
                    <input type="password" v-model="password_change.confirmed_password" class="input w-full border mt-2 flex-1">
                </div>
                <errors :errors="errors" />
                <messages :messages="messages" />
            </div>
            <div class="my-8 px-0 sm:px-10">
                <button href="#" @click.prevent="changePassword()" class="button bg-theme-1 text-white py-2 px-8">Update</button>
            </div>
        </div>
        <!--card body bottom-->
    </div>

    <div  v-else >
         <!--card Header-->
        <div class="flex items-center p-5 border-b  border-gray-200 dark:border-dark-5">
            <h2 class="font-medium text-lg mr-auto">
                User Credentials
            </h2>
            <div @click.prevent="editMood = true" class="outline-none text-theme-6 text-lg py-5 cursor-pointer">
                <fa-icon :icon="['fas' , 'sign-in-alt']" /> Edit Login 
            </div>
        </div>
        <!--card Header-->
        <div class="p-5 ">
            <div class="mx-auto w-full lg:w-11/12 px-6" >
                <div class="intro-x border-b dark:border-dark-5 mt-5" >
                    <div  class="w-32 font-medium text-base inline"> <fa-icon :icon="['fas' , 'envelope']" /> Email:</div>
                    <div  class="outline-none text-lg py-5" v-if="userEmail">{{userEmail}}</div>
                    <div class="outline-none text-theme-6 text-lg py-5 cursor-pointer" @click.prevent="editMood = true" v-else><fa-icon :icon="['fas' , 'plus-circle']" /> Add Email</div>
                </div>
                <div class="intro-x mt-5" >
                    <div  class="w-32 font-medium text-base inline"> <fa-icon :icon="['fas' , 'lock']" /> Password : ************</div>
                    <div class="outline-none text-theme-6 text-lg py-5 cursor-pointer" @click.prevent="editMood = true"><fa-icon :icon="['fas' , 'plus-circle']" /> Update Password</div>
                </div>
            </div>
        </div>
        <!--card Header-->
    </div>   
</div>
</template>
<script>
import { RepositoryFactory } from '@/services/repositories/AuthAPI/RepositoryFactory';
const UserRepo = RepositoryFactory.get('user');

export default {
    name: 'LoginInfo',
    props: {
        user: {
            required: true,
            type: Number | String
        },
    },
    data() {
        return {
            errors: [],
            messages: [],
            
            errors_email: [],
            messages_email: [],

            email_change: {
                user: this.user,
                email: null,
                email_confirm: null,
            },
            password_change: {
                user: this.user,
                old_password: null,
                new_password: null,
                confirmed_password: null,
            },
            editMood : false

        }
    },
    computed: {
        userEmail :function(){
            return this.$store.getters.user.email
        } 
    },
    methods: {
        changeEmail: function() {
            this.errors_email = []
            this.messages_email = []
            UserRepo.changeEmail(this.email_change).then(result => {
                this.messages_email.push(result.data.message)
                setTimeout(() =>{
                    this.editMood = false 
                    this.messages.pop(result.data.message) },1500) 
            }).catch(err => {
                for (var value in err.response.data) {
                    if ( err.response.data.hasOwnProperty(value) ) {
                        var field = value.charAt(0).toUpperCase() + value.slice(1).replace('_', ' ')
                        this.errors_email.push(field + ' ' + err.response.data[value][0])
                    }
                }
            })
        },
        changePassword: function() {
            this.errors = []
            this.messages = []
            UserRepo.changePassword(this.password_change).then(result => {
                this.messages.push(result.data.message)
                setTimeout(() =>{
                    this.editMood = false 
                    this.messages.pop(result.data.message) },1500) 
            }).catch(err => {
                for (var value in err.response.data) {
                    if ( err.response.data.hasOwnProperty(value) ) {
                        var field = value.charAt(0).toUpperCase() + value.slice(1).replace('_', ' ')
                        this.errors.push(field + ' ' + err.response.data[value][0])
                    }
                }
            })
        }
    }
}
</script>