<template>
    <div class="intro-x mt-5 lg:w-2/3 mx-auto ">
        <form class="rounded-md shadow-md bg-white p-5 dark:bg-dark-3">
            <h1 class="intro-x font-bold text-2xl xl:text-3xl text-center pb-3">Report Ad</h1>
            <p class="col-span-12 text-center font-bold text-xs sm:text-lg text-gray-700 border w-full  mx-auto p-2 shadow-md rounded-lg dark:text-gray-200">We Want to Provide You a Better Ad Experience</p>
            <div class="intro-x mt-4 p-8 grid grid-cols-12 border shadow-lg rounded-lg">
                <div class="col-span-12 mt-4">
                    <label for="Reasons" class="font-bold text-md">Reasons For Reporting This Ad :</label>
                    <textarea type="text" required class="input w-full border mt-2 text-xl" />
                </div>
                <div class="col-span-12  mt-6">
                    <label for="url" class="font-bold text-md">Block This Ad URL :</label>
                    <input type="url" placeholder="https://example.com" pattern="https://.*" required class="input w-full border mt-2 text-xl" />
                </div>
                <div class="col-span-12 mt-8 flex flex-row items-center">
                    <label for="img" class="font-bold text-md">Upload ScreenShot Of Ad :</label>
                    <div class="upload ml-12">
                        <label class="upload-area">
                            <input type="file"  accept="image/*" @change="uploadImage" class="input w-full border mt-2" />
                            <span class="upload-button">
                                <fa-icon :icon="['fas', 'arrow-up']" />
                            </span>
                        </label>
                    </div>
                    <div class="w-1/2 h-1/2 ml-12 hidden sm:block" ><img :src="previewImage" /></div>
                </div>
                <div class="col-span-12 block sm:hidden font-bold text-theme-9" v-if="this.previewImage" >Uploaded Image Is Done</div>
                <div class="col-span-12 mt-8">
                     <label for="feedback" class="font-bold text-md">Feedback(optional) :</label>
                    <textarea type="feedback" class="input w-full border mt-2 text-xl" />
                </div>
            </div>
            <div class="col-span-12 mt-4 pt-4 border-t">
                <button class="intro-x button button--lg text-white bg-theme-1 w-64">Submit</button>
            </div>
        </form>
    </div>
</template>
<script>
export default {
    data(){
        return{
            previewImage:""
        }
    },
    methods:{
            uploadImage(e){
                const image = e.target.files[0];
                const reader = new FileReader();
                reader.readAsDataURL(image);
                reader.onload = e =>{
                    this.previewImage = e.target.result;
                };
            }
        }
}
</script>
<style scoped>

.upload label {
  cursor: pointer;
}
.upload label input {
  display: none;
}
.upload label span {
  position: relative;
  width: 60px;
  height: 60px;
  border: 1px dashed;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform .4s;
}
.upload label span i {
  position: absolute;
  font-size: 25px;
}
.upload label span:hover {
  transform: scale(0.8);
}
</style>