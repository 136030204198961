<template>
<div class="col-span-12 lg:col-span-6 ">
    <div class="intro-y flex items-center h-10">
        <h2 class="text-lg font-medium truncate mr-5">
            AD FORMAT YESTERDAY
        </h2>
    </div>
    <div class="mt-5" v-if="isLoading"><point-spinner /></div>
    <div class="mt-5">
        <div class="intro-y" v-for="(placement_group, index) in placement_groups"
         :key="index"
         :class="{displayNone:index>=listCounter}"         
         >
            <div class="box px-4 py-4 mb-3 flex items-center zoom-in">
                <div class="ml-4 mr-auto">
                    <div class="font-medium">{{ placement_group.name }}</div>
                    <div class="text-gray-600 text-xs">
                        <span v-if="PlacementGroupStat(placement_group.id).revenue">${{ PlacementGroupStat(placement_group.id).revenue }}</span>
                        <span v-if="!PlacementGroupStat(placement_group.id).revenue">$0</span>
                    </div>
                </div>
                <div v-if="PlacementGroupStat(placement_group.id).revenue_diff == 0"
                class="py-1 px-2 rounded-full text-xs text-white cursor-pointer font-medium AFY-num-dark" :class="{'bg-theme-5': PlacementGroupStat(placement_group.id).revenue_diff == 0}">
                    {{ PlacementGroupStat(placement_group.id).revenue_diff }}%
                </div>
                <div v-if="PlacementGroupStat(placement_group.id).revenue_diff > 0"
                    class="py-1 px-2 rounded-full text-xs text-white cursor-pointer font-medium" :class="{'bg-theme-9': PlacementGroupStat(placement_group.id).revenue_diff > 0}">
                    {{ PlacementGroupStat(placement_group.id).revenue_diff }}%
                    <fa-icon :icon="['fas', 'chevron-up']" />
                </div>
                <div v-if="PlacementGroupStat(placement_group.id).revenue_diff < 0"
                    class="py-1 px-2 rounded-full text-xs text-white cursor-pointer font-medium" :class="{'bg-theme-6': PlacementGroupStat(placement_group.id).revenue_diff < 0}">
                    {{ PlacementGroupStat(placement_group.id).revenue_diff }}%
                    <fa-icon :icon="['fas', 'chevron-down']" />
                </div>
            </div>
        </div>
        <a href="" v-if="viewMoreBtn" ref="viewMoreBtn" @click.prevent="viewMore()" class="intro-y w-full block text-center rounded-md py-4 border border-dotted border-theme-15 text-theme-16">{{message ||'View More'}}</a>
        <a href="" v-if="!viewMoreBtn"  @click.prevent="showLess()" class="intro-y w-full block text-center rounded-md py-4 border border-dotted border-theme-15 text-theme-16">Show Less</a>  
    </div>
</div> 
</template>

<script>
import { RepositoryFactory } from '@/services/repositories/NetworkAPI/RepositoryFactory'
const PlacementsGroupRepo = RepositoryFactory.get('groups')
const GroupStatisticsRepo = RepositoryFactory.get('groups_statistics')

export default {
    data() {
        return {
            ad_formats: [],
            placement_groups: [],
            placement_groups_ids: [],
            isLoading: false,
            viewMoreBtn:true,
            listCounter:4,
            message:""
        }
    },
    created() {
        this.loadGroupStatisticsStatus()
        this.loadPlacementGroups()
    },
    methods: {
        loadGroupStatisticsStatus: function() {
            this.isLoading = true
            GroupStatisticsRepo.status().then(result => {
                this.ad_formats = result.data.ad_format
                this.filterPlacementGroupsList()
            }).catch(err => {
                this.isLoading = false
            })
        },
        filterPlacementGroupsList: function() {
            let list = []
            this.ad_formats.forEach(function(element) {
                list.push(element.placement_group)
            })
            this.placement_groups_ids = list
            this.loadPlacementGroups()
        },
        loadPlacementGroups: function() {
            PlacementsGroupRepo.all().then(result => {
                this.placement_groups = result.data.results
                this.isLoading = false
            }).catch(err => {
                this.isLoading = false
            })
        },
        PlacementGroupStat: function(placement_group_id) {
            var placement_group = this.ad_formats.filter(element => { return element.placement_group_id == placement_group_id })[0]
            if(placement_group)
                return placement_group
            else
                return {
                    placement_group: placement_group_id,
                    revenue: 0,
                    revenue_diff: 0
                }
        },
        PlacementGroup: function(placement_group_id) {
            var placement_group = this.placement_groups.filter(element => { return element.id == placement_group_id })[0]
            if(placement_group)
                return placement_group
        },
        PlacementGroupName: function(placement_group_id) {
            var placement_group = this.placement_groups.filter(element => { return element.id == placement_group_id })[0]
            if(placement_group)
                return placement_group.name
        },
        viewMore:function(){
            if (this.placement_groups.length > 4){
                this.listCounter=this.listCounter + 4;    
                if(this.listCounter>=this.placement_groups.length)
                    this.viewMoreBtn =false
            }
           else{
                this.message="No More Views" 
                this.$refs.viewMoreBtn.style.cursor = "not-allowed"
            }
        },
        showLess:function(){
            this.listCounter=4;
            this.viewMoreBtn=true;
        },
    },
}
</script>
<style scoped>
.displayNone{
    display:none;
    }
</style>
