<template>
<div>

  <div class="col-span-12 lg:col-span-12 mt-5">
    <!-- BEGIN: Basic Datepicker -->
    <div class="intro-y box">
        <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200">
            <h2 class="font-medium text-base mr-auto">
              Ads.txt
              <point-spinner v-if="!isLoaded" />
            </h2>
            <div class="w-full sm:w-auto flex items-center sm:ml-auto mt-3 sm:mt-0">
                <div class="mr-3"></div>
                <a href="#" class="mr-3" @click="getAdsTxtFile()" data-toggle="tooltip" data-placement="top" title="Download">
                  <i data-feather="download"></i>
                </a>
                <a href="#" class="mr-3" data-toggle="tooltip" data-placement="top" title="Copy ads to clipboard">
                  <i data-feather="copy"></i>
                </a>
            </div>
        </div>
        <div class="col-span-12 lg:col-span-12 p-5">
            <textarea class="input w-full border" style="height: 400px;" v-model="ads"></textarea>
        </div>
    </div>
    <!-- END: Basic Datepicker -->
    <!-- BEGIN: Input Group -->
</div>

  <!-- /.card-body -->
  <div class="col-span-12 lg:col-span-12">
      <h2 class="font-normal text-sm border-b py-4 pl-4 mt-1">Please make sure your ads are up to date on your websites</h2>
  </div>
</div>
</template>

<script>
import { RepositoryFactory } from '@/services/repositories/NetworkAPI/RepositoryFactory'
const AdsRepo = RepositoryFactory.get('ads')

export default {
  name: "Ads",
  components: {
  },
  data() {
    return {
      ads: [],
      isLoaded: false
    }
  },
  created() {
    this.getAdsTxt()
    this.$feather.replace()
  },
  updated() {
    // jQuery('[data-toggle="tooltip"]').tooltip()
  },
  mounted() {
    this.$feather.replace()
  },
  methods: {
    getAdsTxt: function() {
      AdsRepo.text().then(result => {
        this.ads = result.data
        this.isLoaded = true
      }).catch(err => {
        this.isLoaded = true
      })
    },
    getAdsTxtFile: function() {
      AdsRepo.text().then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      
      link.href = url;
      link.setAttribute('download', 'ads.txt');
      link.innerHTML = 'Download ads.txt'
      link.click()

      document.getElementById("download-links").innerHTML = ""
      document.getElementById("download-links").appendChild(link)
      })
    },
  }
}
</script>

<style scoped>
  text-area {
    height: 600px; width: 100%; 
  }
</style>