<template>
<div>
  <div class="grid grid-cols-12 gap-6">
    <div class="col-span-12  md:col-span-6 xl:col-span-4" >
      <WebsitesList :websites='websites' :isLoading="isLoading" @search="loadSearchResult" />
        <br />
       <div class="intro-y flex flex-wrap sm:flex-row sm:flex-no-wrap items-center mt-3">
        <ul class=" pagination">
            <li class="">
              <a class="pagination__link pagination__link--active" href="#" @click.prevent="previousPage"><i data-feather="chevrons-left"></i>Previous</a>
            </li>
            <li class="">
              <a class="pagination__link">{{ currentPage }} / {{ pageCount }}</a>
            </li>
            <li class="">
              <a class="pagination__link pagination__link--active" href="#" @click.prevent="nextPage">Next<i data-feather="chevrons-right"></i></a>
            </li>
        </ul>
       </div> 
    </div>
      
      <div class="col-span-12  md:col-span-6 xl:col-span-8">
        <router-view ></router-view>
      </div>
  </div>
</div>
</template>
<script>
import WebsitesList from '@/components/websites/List'
import { RepositoryFactory } from '@/services/repositories/NetworkAPI/RepositoryFactory';
const WebsitesRepo = RepositoryFactory.get('websites');

export default {
  components: {
    WebsitesList,
  },
  data() {
    return {
      isLoading: false,
      currentPage: 1,
      totalRecords: 0,
      resultsLimit: 10,
      websites: [],
    }
  },
  created: function() {
    this.loadWebsites();
    this.$feather.replace();
  },
  mounted:function(){
    this.$feather.replace()
  },
  methods: {
    loadWebsites: function() {
      this.isLoading = true
      WebsitesRepo.page(this.currentPage).then(result => {
        this.totalRecords = result.data.count
        this.websites = result.data.results
        this.isLoading = false
      }).catch(err => {
        this.websites = []
        this.isLoading = false
      })
    },
    loadWebsite: function() {
      WebsitesRepo.page(this.currentPage, this.resultsLimit).then(result => {
        this.totalRecords = result.data.count
        this.websites = result.data.results
      }).catch(err => {
        this.websites = []
      })
    },
    loadSearchResult: function(search_text) {
        this.isLoading = true
        WebsitesRepo.page(this.currentPage, this.resultsLimit, search_text).then(result => {
          this.totalRecords = result.data.count
          this.websites = result.data.results
          this.isLoading = false
        }).catch(err => {
          this.websites = []
          this.isLoading = false
        })
    },
    nextPage: function() {
      if(this.currentPage != this.pageCount || this.currentPage > this.pageCount) {
        this.currentPage = this.currentPage + 1
        this.loadWebsites()
      }
    },
    previousPage: function() {
      if(this.currentPage != 1 ) {
        this.currentPage = this.currentPage - 1
        this.loadWebsites()
      }
    },
  },
  computed: {
    pageCount: function() {
      return Math.ceil(this.totalRecords / this.resultsLimit)
    },
  },
}
</script>
<style scoped>

</style>