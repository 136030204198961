import Repository from './Repository';

const resource = "/websites";
export default {
    all() {
        return Repository.get(`${resource}/?limit=1000`);
    },
    ids(ids) {
        return Repository.get(`${resource}/?ids=${ids}&limit=1000`);
    },
    get(id) {
        return Repository.get(`${resource}/${id}/`);
    },
    post(data) {
        return Repository.post(`${resource}/`, data);
    },
    patch(id, data) {
        return Repository.patch(`${resource}/${id}/`, data);
    },
    page(page, limit=10, search='') {
        var offset = (page - 1) * limit
        if(search) { search = `&search=${search}` }
        return Repository.get(`${resource}/?limit=${limit}&offset=${offset}${search}`);
    },
    categories() {
        return Repository.options(`${resource}/`);
    }
}