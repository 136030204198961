import { render, staticRenderFns } from "./Assigned.vue?vue&type=template&id=18d7d87f&scoped=true&"
import script from "./Assigned.vue?vue&type=script&lang=js&"
export * from "./Assigned.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18d7d87f",
  null
  
)

export default component.exports