<template>
<div>
  <!-- Main Sidebar Container -->
  <!-- BEGIN: Side Menu -->
  <nav class="side-nav px-4">
      <a href="" class="intro-x flex items-center pl-5 pt-4">
          <CompanyLogo position="sidebar" />
      </a>
      <div class="side-nav__devider  my-6"></div>
      <ul>
          <li>
            <router-link :to="{ name: 'dashboard'}" exact-active-class="side-menu--active" class="side-menu">
              <div class="side-menu__icon"> <i data-feather="home"></i> </div>
              <div class="side-menu__title"> Dashboard </div>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'websites'}" active-class="side-menu--active" class="side-menu">
              <div class="side-menu__icon"> <i data-feather="cpu"></i> </div>
              <div class="side-menu__title"> Manage Websites </div>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'users'}" active-class="side-menu--active" class="side-menu">
              <div class="side-menu__icon"> <i data-feather="users"></i> </div>
              <div class="side-menu__title"> Manage Users </div>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'tags'}" active-class="side-menu--active" class="side-menu">
              <div class="side-menu__icon"> <i data-feather="tag"></i> </div>
              <div class="side-menu__title"> Tags </div>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'ads'}" active-class="side-menu--active" class="side-menu">
              <div class="side-menu__icon"> <i data-feather="file-text"></i> </div>
              <div class="side-menu__title"> Ads.txt </div>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'reports'}" active-class="side-menu--active" class="side-menu">
              <div class="side-menu__icon"> <i data-feather="pie-chart"></i> </div>
              <div class="side-menu__title"> Reports </div>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'report_ad'}" active-class="side-menu--active" class="side-menu">
              <div class="side-menu__icon"> <i data-feather="message-circle"></i> </div>
              <div class="side-menu__title"> Report Ad </div>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'white_list'}" active-class="side-menu--active" class="side-menu">
              <div class="side-menu__icon"> <i data-feather="pie-chart"></i> </div>
              <div class="side-menu__title"> White List </div>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'payments'}" active-class="side-menu--active" class="side-menu">
              <div class="side-menu__icon"> <i data-feather="shield"></i> </div>
              <div class="side-menu__title"> Payments </div>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'profile'}" active-class="side-menu--active" class="side-menu">
              <div class="side-menu__icon"> <i data-feather="user"></i> </div>
              <div class="side-menu__title"> Profile </div>
            </router-link>
          </li>
          <li class="side-nav__devider my-6"></li>
          <li>
            <a href="https://adcrew.co/creative-center/" target="_blank" active-class="side-menu--active" class="side-menu">
              <div class="side-menu__icon"> <i data-feather="video"></i> </div>
              <div class="side-menu__title"> Creative Center </div>
            </a>
          </li>
          <li>
            <a href="mailto:support@adcrew.co" target="_blank" active-class="side-menu--active" class="side-menu">
              <div class="side-menu__icon"> <i data-feather="help-circle"></i> </div>
              <div class="side-menu__title"> Support </div>
            </a>
          </li>
      </ul>
  </nav>
  <!-- END: Side Menu -->
</div>
</template>
<script>
import CompanyLogo from '@/components/core/CompanyLogo'

export default {
  name: 'Sidebar',
  cache: false,
  components:{
    CompanyLogo
  },
  props: {
    company_logo: {
      type: String,
    },
    user: {
      type: Object,
    },
  },
  created() {
    this.$feather.replace()
  },
  methods: {
    logout() {
      if (event) event.preventDefault()
      this.$store.dispatch('AUTH_LOGOUT').then(() => {
        this.$router.push('/login')
      })
    }
  }, 
  computed: {
    userFullName() {
      return this.user.first_name + " " + this.user.last_name
    },
    verifiedUser() {
      if(this.user.is_verified) {
        return 'Verified'
      } else {
        return 'Not Verified'
      }
    },
  }
}
</script>

<style scoped>

</style>