import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store/store'
import "./filters"

// imported libs 
import VAnimateCss from 'v-animate-css'
import Notifications from 'vue-notification'
import Vuelidate from 'vuelidate'
import VueSwal from 'vue-swal'

import feather from 'feather-icons'
import moment from 'moment'
import Chart from 'chart.js/auto';

import { library } from '@fortawesome/fontawesome-svg-core'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// custom componetns
import PointSpinner from '@/components/core/util/Spinners/Points'
import CompanyLogo from '@/components/core/CompanyLogo'
import DisplayError from '@/components/core/display/Error'
import DisplayMessage from '@/components/core/display/Message'
import DisplayInfo from '@/components/core/display/Info'

import VueFloatLabel from 'vue-float-label'

import './assets/styles/index.css';

import "jquery-ui";

library.add(fas)
library.add(far)

Vue.component('fa-icon', FontAwesomeIcon)

// custom componetns
Vue.component('point-spinner', PointSpinner)
Vue.component('errors', DisplayError)
Vue.component('messages', DisplayMessage)
Vue.component('info', DisplayInfo)
Vue.component('company-logo', CompanyLogo)

Vue.use(VueSwal)
Vue.use(VAnimateCss);
Vue.use(Vuelidate)
Vue.use(Notifications)
Vue.use(VueFloatLabel)

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {

  if(to.query.impersonating) {
    store.dispatch('IMPERSONATION', to.query.impersonating).then(
      router.push({name: 'dashboard'})
    )

    if(to.matched.some(record => record.meta.requiresAuth === true)) {
      store.dispatch('VERIFY_TOKEN')
      if (store.getters.isAuthenticated === false) {
        store.dispatch('AUTH_LOGOUT')
        if(router.currentRoute.name !== 'login')
          router.push({name: 'login'})
      }
    }

    if(to.matched.some(record => record.meta.requiresAuth) === false) {
      if (store.getters.isAuthenticated === true) {
        router.push({name: 'dashboard'})
      }
    }
    next()
  }
  
  if(to.matched.some(record => record.meta.requiresAuth === true)) {
    store.dispatch('VERIFY_TOKEN')
    if (store.getters.isAuthenticated === false) {
      store.dispatch('AUTH_LOGOUT')
      if(router.currentRoute.name !== 'login')
        router.push({name: 'login'})
    }
  }

  if(to.matched.some(record => record.meta.requiresAuth) === false) {
    if (store.getters.isAuthenticated === true) {
      router.push({name: 'dashboard'})
    }
  }

  next()
})

Object.defineProperty(Vue.prototype, '$chart', { value: Chart })
Object.defineProperty(Vue.prototype, '$feather', { value: feather })
Object.defineProperty(Vue.prototype, '$moment', { value: moment })

window.$ = require('jquery')
window.JQuery = require('jquery')

window.onload = function () {

  new Vue({
    router,
    store,
    axios,
    render: h => h(App)
  }).$mount('#app')
}