import Repository from '../NetworkAPI/Repository';

const resource = "/auth/users";
export default {
    all() {
        return Repository.get(`${resource}/`);
    },
    get(id) {
        return Repository.get(`${resource}/${id}/`);
    },
    post(data) {
        return Repository.post(`${resource}/`, data);
    },
    patch(id, data) {
        return Repository.patch(`${resource}/${id}/`, data);
    },
    page(page, limit=10, search='') {
        var offset = (page - 1) * limit
        if(search) { search = `&search=${search}` }
        return Repository.get(`${resource}/?limit=${limit}&offset=${offset}${search}`);
    },
    profile(id) {
        return Repository.get(`${resource}/profiles/${id}`);
    }
}