import UserRepo from './UserRepo';
import UsersRepo from './UsersRepo';
import ProfileRepo from './ProfileRepo';
import BankRepo from './BankRepo';
import AuthRepo from './AuthRepo';
import AssignedRep from './AssignedRepo'

const repositories = {
    auth: AuthRepo,
    user: UserRepo,
    users: UsersRepo,
    profile: ProfileRepo,
    bank: BankRepo,
    assign: AssignedRep
}

export const RepositoryFactory = {
    get: name => repositories[name],
};