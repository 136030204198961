<template>
<div class="flex mt-8" >
    <div  class="rounded-md shadow-md bg-white dark:bg-dark-3 w-full">
        <div class="m-8 border-b">
            <h2 class="intro-x font-medium text-lg xl:text-lg text-left">New website</h2>
        </div>

        <div class="m-8">
            <div role="intro-x">
                <div class="flex flex-col items-left justify-between">
                    <label class="text-lg mt-5">Name</label>
                    <input type="text" class=" w-full intro-x login__input input input--lg border border-gray-300 block" placeholder="" v-model="website.name">
                </div>

                <div class="flex flex-col items-left justify-between">
                    <label class="text-lg mt-5">Categories</label>
                    <select id="categories"  class="p-2 w-full inline intro-x input input--lg border border-gray-300 block mt-4 cursor-pointer" v-model="website.category">
                        <option v-for="category in categories" :key="category.value" :value="category.value"
                        :selected="website.category"> {{ category.display_name }} </option>
                    </select>
                </div>
                <div class="flex flex-col items-left justify-between">
                    <label class="text-lg mt-5">URL</label>
                    <input type="text" class=" w-full intro-x login__input input input--lg border border-gray-300 block mt-4" placeholder=""  v-model="website.url">
                </div>
                <div class="flex flex-col items-left justify-between">
                    <label class="text-lg mt-5">Assign publisher</label>
                    <select id="publisher" class="p-2 select2 w-full inline intro-x login__input input input--lg border border-gray-300 block mt-4 cursor-pointer" v-model="website.publisher">
                        <option value selected> None </option>
                        <option v-for="user in users" :key="user.id" :value="user.id"> {{ user.first_name }} {{ user.last_name }} </option>
                    </select>
                </div>
                <div class="mt-10 pt-2 border-t">
                    <button class="button button--lg text-white bg-theme-1 w-64" @click.prevent="submit"> Submit </button>
                </div>
            </div>
        </div>

    </div>
</div>

</template>

<script>
import { RepositoryFactory } from '@/services/repositories/NetworkAPI/RepositoryFactory'
const WebsitesRepo = RepositoryFactory.get('websites')
const UsersRepo = RepositoryFactory.get('users')

export default {
    data() {
        return {
            website: {
                ad_type: []
            },
            users: [],
            categories: []
        }
    },
    mounted() {
        this.loadUsers()
        this.getWebsitesCategories()

        //Initialize Select2 Elements
        $('.select2').select2({
            width: '100%',
        })

        var self = this
        $('#publisher').on("change", function(e) { 
            self.website.publisher = $(this).val()
        });
        $('#categories').on("change", function(e) { 
            self.website.category = $(this).val()
        });
    },
    methods: {
        submit: function() {
            if(typeof this.website.id === 'undefined') {
                this.create(this.website)
            } else {
                this.update(this.website)
            }
        },
        clear: function() {
            this.errors = []
            this.messages = []

            this.website = {}
        },
        create: function(website) {
            WebsitesRepo.post(website).then(result => {
                if(typeof result.data.id !== 'undefined' ) 
                    this.website = result.data
                    this.$notify({
                        group: 'ops',
                        type: 'success',
                        title: 'Operation success',
                        text: 'Successfully added submited'
                    })
                    this.$emit('updateList', this.website)
            }).catch(err => {
                this.$notify({
                    group: 'ops',
                    type: 'error',
                    title: 'Operation failed',
                    text: err.response.data
                })
            })
        },
        update: function(website) {
            WebsitesRepo.patch(this.website.id, website).then(result => {
                if(typeof result.data.id !== 'undefined' ) 
                    this.website = result.data
                    this.$notify({
                        group: 'ops',
                        type: 'success',
                        title: 'Operation success',
                        text: 'Successfully updated'
                    })
                    this.$emit('refreshList', true)
            }).catch(err => {
                this.$notify({
                    group: 'ops',
                    type: 'error',
                    title: 'Operation failed',
                    text: err.response.data
                })
            })
        },
        loadUsers: function() {
            UsersRepo.all().then(result => {
                this.users = result.data.results
            }).catch(err => {})
        },
        getWebsitesCategories: function () {
            WebsitesRepo.categories().then(result => {
                this.categories = result.data.actions.POST.category.choices
            }).catch(error => {
                console.log(error)
            })
        },
    },
    watch: {
    }
}
</script>