<template>
    <div class="grid grid-cols-12 gap-6">
        <div class="col-span-12 lg:col-span-3">
            <InvoicesTotal :wallet="wallet" :website="website" class="box" />
        </div>
        <div class="col-span-12 lg:col-span-9 col-end-13 lg:col-end-13 mt-5">
            <!--filter bar-->
            <div class="box w-full px-4 py-2">
                <div class="intro-y flex flex-col sm:flex-row pl-4 sm:pl-0 items-baseline ">
                    <div class="w-full md:w-3/12 p-3 self-center ">
                        <label class="">Date range:</label>
                        <div  id="input-group-datepicker">
                            <div class="relative mr-2 mt-2">
                                <div class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 border text-gray-600 dark:date-bg-dark"> <i data-feather="calendar" class="w-4 h-4"></i> </div>
                                <input type="text" id="basic-datepicker" class="daterange input w-full border pl-12">
                            </div>
                        </div>
                    </div>
                    <div class="w-full md:w-3/12 p-3 self-center ">
                        <div class="" id="basic-select">
                            <label class="">Limit</label>
                            <div class="mr-2 mt-2">
                                <select class="select2 border input w-full"  v-model="query.limit">
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                    <option selected value="30">30</option>
                                    <option value="60">60</option>
                                    <option value="100">100</option>
                                </select>
                            </div>    
                        </div>        
                    </div>
                    <div class="w-full md:w-3/12 p-3 self-center ">
                        <div class="" id="basic-select">
                            <label class="">Order</label>
                            <div class="mr-2 mt-2">
                                <select class="select2 border input w-full"  v-model="query.order">
                                    <option selected="selected" value="-date">Newest</option>
                                    <option value="date">Older</option>
                                </select>
                            </div>    
                        </div>  
                    </div>
                    <div class="w-full md:w-1/12 p-3 self-center ">
                        <div class="search ">
                            <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-1 sm:mt-8" @click.prevent="loadInvoices()">
                                <fa-icon :icon="['fa', 'search']" />
                            </button>
                        </div>
                    </div>
                    <div class="w-full md:w-2/12 p-3 self-start hidden sm:block ">
                        <div class="intro-y flex flex-col sm:flex-row-reverse">
                            <button class="p-3" @click.prevent="clear()">
                                <fa-icon :icon="['fa', 'eraser']" />
                            </button>
                            <button class="p-3" @click.prevent="print()">
                                    <fa-icon :icon="['fa', 'print']" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!--end of filter bar-->  
            <!--table report-->     
            <div class="intro-y overflow-auto  mt-8">
                <table class="table table-report -mt-2">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th class="text-center">Amount</th>
                            <th class="text-center">Deducted</th>
                            <th class="text-center">Final</th>
                            <th>Description</th>
                            <th class="text-center">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr v-for="invoice in invoices" :key="invoice.id">
                            <td class="truncate">{{ invoice.date }}</td>
                            <td class="text-center">${{ invoice.amount | withComma }}</td>
                            <td class="text-center">${{ invoice.deduction | withComma }}</td>
                            <td class="text-center">${{ invoice.final | withComma }}</td>
                            <td class="truncate">{{ invoice.description }}</td>
                            <td class="text-center">
                                <span class="alert btn-xs" :class="{
                                    'text-theme-9 block': invoice.status == 'paid',
                                    'text-theme-1 block': invoice.status == 'approved',
                                    'text-theme-6 block': invoice.status == 'deductible'}"> {{ invoice.status | capitalize }} 
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!--end of table report-->
            <!--pagination-->
            <div class="intro-y flex flex-wrap sm:flex-row sm:flex-no-wrap items-center mt-3">
                <ul class="pagination ">
                    <li class="">
                        <a class="pagination__link pagination__link--active" href="#" @click.prevent="previousPage"><i data-feather="chevrons-left"></i> Previous</a>
                    </li>
                    <li class="">
                        <a class="pagination__link">{{ currentPage }} / {{ pageCount }}</a>
                    </li>
                    <li class="">
                        <a class="pagination__link pagination__link--active" href="#" @click.prevent="nextPage">Next <i data-feather="chevrons-right"></i></a>
                    </li>
                </ul>
            </div>   
            <!--end of pagination-->
        </div>     
    </div>
</template>
<script>
import InvoicesTotal from '@/components/wallets/side/InvoicesTotal'
import { RepositoryFactory } from '@/services/repositories/NetworkAPI/RepositoryFactory' 
const WalletsRepo = RepositoryFactory.get('wallets')
const InvoicesRepo = RepositoryFactory.get('invoices')
const WebsitesRepo = RepositoryFactory.get('websites')

export default {
    components: {
        InvoicesTotal,
    },
    data() {
        return {
            wallet: {},
            wallet_id: 0,
            invoices: [],
            website: {},
            selectedInvoice: {},

            show: false,
            currentPage: 1,
            totalRecords: 0,
            resultsLimit: 10,
            isLoaded: true,
            query: {
                start_date: '',
                end_date: '',
                limit: 30,
                order: '-date',
                search: 0
            }
        }
    },
    mounted() {
        this.$feather.replace()
        var self = this
        $('#basic-datepicker').daterangepicker({
            ranges   : {
                'Today'       : [this.$moment(), this.$moment()],
                'Yesterday'   : [this.$moment().subtract(1, 'days'), this.$moment().subtract(1, 'days')],
                'Last 7 Days' : [this.$moment().subtract(6, 'days'), this.$moment()],
                'Last 30 Days': [this.$moment().subtract(29, 'days'), this.$moment()],
                'This Month'  : [this.$moment().startOf('month'), this.$moment().endOf('month')],
                'Last Month'  : [this.$moment().subtract(1, 'month').startOf('month'), this.$moment().subtract(1, 'month').endOf('month')]
                },
                startDate: this.$moment().subtract(29, 'days'),
                endDate  : this.$moment()
            },
            function (start, end) {
                self.query.start_date = start.format('YYYY-MM-DD')
                self.query.end_date = end.format('YYYY-MM-DD')
            }
        )
    },
    created() {
        this.loadWallet()
        this.loadInvoices()
    },
    updated() {
        //jQuery('[data-toggle="tooltip"]').tooltip()
    },
    methods: {
        loadInvoices: function() {
            this.isLoaded = false
            InvoicesRepo.page(this.wallet_id, this.currentPage, this.query.limit, this.query.start_date, this.query.end_date, this.query.order).then(result => {
                this.totalRecords = result.data.count
                this.invoices = result.data.results

                this.isLoaded = true
            }).catch(err => {
                this.isLoaded = true
            })
        },
        loadWallet: function() {
            WalletsRepo.get(this.wallet_id).then(result => {
                this.wallet = result.data
                this.wallet_id = this.wallet.id
                this.loadWebsite(this.wallet.website_id)
            })
        },
        loadWebsite: function(website_id) {
            WebsitesRepo.get(website_id).then(result => {
                this.website = result.data
            })
        },
        edit: function(invoice) {
            this.selectedInvoice = invoice
            this.show = true
        },
        print: function() {

        },
        clear: function() {
            this.query.start_date = ''
            this.query.end_date = ''
            this.loadInvoices()
        },
        updateWallet: function() {
            this.loadWallet()
        },
        closeInvoiceModel: function(invoice) {
            this.show = false
            this.selectedInvoice = {}
        },
        nextPage: function() {
            if(this.currentPage != this.pageCount || this.currentPage > this.pageCount) {
                this.currentPage = this.currentPage + 1
                this.loadInvoices()
            }
        },
        previousPage: function() {
            if(this.currentPage != 1 ) {
                this.currentPage = this.currentPage - 1
                this.loadInvoices()
            }
        },
  },
  computed: {
    pageCount: function() {
            return Math.ceil(this.totalRecords / this.resultsLimit)
        },
    },
    watch: {
        '$route.params.id': {
            handler: function (id) {
                if(typeof id !== 'undefined') {
                    this.wallet_id = id
                }
            },
            deep: true,
            immediate: true
        },
    }
}
</script>