<template>
    <div class="grid grid-cols-12">
         <!--top bar-->
        <div class="col-span-12 sm:col-span-12">
            <div class="intro-y block sm:flex items-center justify-between box mt-5 p-5">
              <div class="bg-white mr-2 w-1/4 rounded-md shadow-sm flex items-center">
                <select id="website" name="website" class="select2 input w-full sm:w-56 box shadow-md pl-10 m-1 cursor-pointer">
                    <option selected disabled hidden> -- Websites --</option>
                    <option v-for="website in websites" 
                    :key="website.id" 
                    :value="website.id"
                    :selected="website.id === websites[0].id">{{ website.name }} - {{ website.url }}</option>
                </select>
                  <a href="#" @click.prevent="Assign">
                      <i data-feather="plus" class="inline"></i>
                  </a>
              </div>
                <h3 class="font-medium truncate mr-5"></h3>
                <div class="search hidden sm:block" >
                    <input type="search" name="table_search" class="search__input input placeholder-theme-13" placeholder="Search">
                    <button  @click.prevent="loadSearchResult()"><i data-feather="search" class="search__icon"></i> </button>
                </div>           
            </div>
            <!--end of top bar --> 
            <!--table-->  
            <div class="intro-y overflow-auto  mt-8 sm:mt-0">
                <table class="table table-report sm:mt-2">
                    <thead>
                        <tr>
                            <th class="whitespace-no-wrap">Name</th>
                            <th class="whitespace-no-wrap">URL</th>
                            <th class="whitespace-no-wrap">Status</th>
                            <th class="whitespace-no-wrap">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="isLoading">
                            <td colspan="9">
                                <point-spinner class="text-center" />
                            </td>
                        </tr>
                        <tr v-if="!isLoading && assignments.length == 0">
                            <td colspan="9" class="text-center">
                                No available website assigned
                            </td>
                        </tr>
                        <tr v-for="assigned in assignments" :key="assigned.id" v-if="Website(assigned.website)">
                            <td>{{ Website(assigned.website).name }}</td>
                            <td>{{ Website(assigned.website).url }}</td>
                            <td>
                                <span class="py-1 px-2 rounded-full text-xs text-white cursor-pointer font-medium" :class="{
                                    'bg-theme-9 ': Website(assigned.website).status == 'active',
                                    'bg-theme-4 ': Website(assigned.website).status == 'pending',
                                    'bg-theme-6 ': Website(assigned.website).status == 'suspended'}"> {{ Website(assigned.website).status | capitalize }} 
                                </span>
                            </td>
                            <td class="text-center">
                                <div class="flex items-center text-theme-6">
                                    <a href="#" class="text-theme-6" @click.prevent="Unassign(assigned.id)" >Unassign</a>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!--end of table--> 
            <!--pagination-->
            <div class="intro-y flex flex-wrap sm:flex-row sm:flex-no-wrap items-center mt-3">
                <ul class="pagination">
                    <li class="">
                        <a class="pagination__link pagination__link--active" href="#" @click.prevent="previousPage"><i data-feather="chevrons-left"></i> Previous</a>
                    </li>
                    <li class="">
                        <a class="pagination__link">{{ currentPage }} / {{ pageCount }}</a>
                    </li>
                    <li class="">
                        <a class="pagination__link pagination__link--active" href="#" @click.prevent="nextPage">Next <i data-feather="chevrons-right"></i></a>
                    </li>
                </ul>
            </div> 
            <!--pagination--> 
        </div>  
    </div>
</template>
<script>
import { RepositoryFactory } from '@/services/repositories/NetworkAPI/RepositoryFactory';
const WebsitesRepo = RepositoryFactory.get('websites');
const AssignedRepo = RepositoryFactory.get("assignments")

export default {
  props: {
    user: {
      required: true,
      type: Number | String
    }
  },
  data() {
    return {
      isLoading: false,
      currentPage: 1,
      totalRecords: 0,
      resultsLimit: 10,

      websites: [],
      selectedWebsite: 0,
      assignments: []
    }
  },
  created: function() {
    this.loadWebsites()
    this.loadAssignedWebsites(this.user, 'publisher')
    this.$feather.replace()
  },
  mounted: function() {
    this.$feather.replace()

    //Initialize Select2 Elements
    $('.select2').select2({
        width: '100%',
    })

    var self = this
    $('#website').on("change", function(e) { 
        self.selectedWebsite = $(this).val()
    });

  },
  methods: {
    Unassign: function(id) {
      AssignedRepo.update(id, {website: 0}).then(result => {
        this.loadAssignedWebsites(this.user, 'publisher')
        this.$notify({ 
            group: 'ops',
            type: 'success',
            text: 'Website Unassinged Successfully' 
        })
      }).catch(err => {
          this.$notify({ 
              group: 'ops',
              type: 'error',
              title: 'Faild to Unassinged Website',
              text: err.response.data
          })
      })
    },
    Assign: function(id) {
      var data = {
        'network': this.network.id,
        'publisher': this.user,
        'website': this.selectedWebsite
      }
      AssignedRepo.add(data).then(result => {
        this.loadAssignedWebsites(this.user, 'publisher')
        this.$notify({ 
            group: 'ops',
            type: 'success',
            text: 'Website Assinged Successfully' 
        })
      }).catch(err => {
          this.$notify({ 
              group: 'ops',
              type: 'error',
              title: 'Faild to Assinged website',
              text: err.response.data
          })
      })
    },
    loadWebsites: function() {
      this.isLoading = true
      WebsitesRepo.page(this.currentPage).then(result => {
        this.totalRecords = result.data.count
        this.websites = result.data.results
        
        this.isLoading = false
      }).catch(err => {
        this.websites = []
        this.isLoading = false
      })
    },
    loadAssignedWebsites(user_id, user_role) {
        AssignedRepo.filter(user_id, user_role).then(result => {
          this.assignments = result.data.results
          this.filterAssignedWebsites()
        })
    },
    filterAssignedWebsites() {
      var filtered = this.assignments.filter(element => {
        return element.website !== 0 && element.website !== null
      })

      this.assignments = filtered
    },
    loadSearchResult: function(search_text) {
        this.isLoading = true
        WebsitesRepo.page(this.currentPage, this.resultsLimit, search_text).then(result => {
          this.totalRecords = result.data.count
          this.websites = result.data.results
          this.isLoading = false
        }).catch(err => {
          this.websites = []
          this.isLoading = false
        })
    },
    Website: function(website_id) {
        var website = this.websites.filter(element => { return element.id == website_id })[0]
        if(website)
          return website
    },
    WebsiteName: function(website_id) {
        var website = this.websites.filter(element => { return element.id == website_id })[0]
        if(website)
          return website.name
    },
    nextPage: function() {
      if(this.currentPage != this.pageCount || this.currentPage > this.pageCount) {
        this.currentPage = this.currentPage + 1
        this.loadWebsites()
      }
    },
    previousPage: function() {
      if(this.currentPage != 1 ) {
        this.currentPage = this.currentPage - 1
        this.loadWebsites()
      }
    },
  },
  computed: {
    pageCount: function() {
      return Math.ceil(this.totalRecords / this.resultsLimit)
    },
    network: function() {
      return this.$store.getters.user
    }
  },
}
</script>
<style scoped>

</style>