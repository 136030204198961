<template>
    <div class="col-span-12">
        <!--impression-->
        <div class="intro-y flex items-center mt-5 h-15">
            <h2 class="text-lg font-medium truncate mr-5">
                Impressions
            </h2>
        </div>
        <div class="grid grid-cols-10 gap-1 mt-5">
            <div class="col-span-10 sm:col-span-5 md:col-span-4 lg:col-span-3 xl:col-span-2 intro-x">
                <div class="">
                    <div class=" p-3 background1 background-item ">
                        <div class="flex">
                            <fa-icon :icon="['fas', 'chart-bar']" size="2x" class="report-box__icon text-theme-10" />
                            <div class="ml-auto">
                                <div v-if="impressions.today_diff > 0" class="report-box-indicator bg-theme-9 tooltip cursor-pointer"
                                    :title="impressions.today_diff + '% Higher than Yesterday'"> 
                                    {{ impressions.today_diff }}% &nbsp;<fa-icon :icon="['fas', 'chevron-up']" class="w-4 h-4" />&nbsp;
                                </div>
                                <div v-if="impressions.today_diff < 0" class="report-box-indicator bg-theme-6 tooltip cursor-pointer"
                                    :title="impressions.today_diff + '% Lower than Yesterday'"> 
                                    {{ impressions.today_diff }}% &nbsp;<fa-icon :icon="['fas', 'chevron-down']" class="w-4 h-4" />&nbsp;
                                </div>
                            </div>
                        </div>
                        <div class="text-xl font-bold leading-8 mt-1">
                            {{ impressions.today | withComma }}
                            <span v-if="isLoading || impressions.today == null">0</span>
                            <small class="text-base text-gray-600 ml-1">IMP</small>
                        </div>
                        <div class="text-base text-gray-600 mt-1">Today</div>
                    </div>
                </div>
            </div>
            <div class="col-span-10 sm:col-span-5 md:col-span-4 lg:col-span-3 xl:col-span-2 intro-x">
                <div class="">
                    <div class=" p-3 background2 background-item">
                        <div class="flex">
                            <fa-icon :icon="['fas', 'chart-bar']" size="2x" class="report-box__icon text-theme-11" />
                            <div class="ml-auto">
                                <div v-if="impressions.yesterday_diff > 0" class="report-box-indicator bg-theme-9 tooltip cursor-pointer"
                                    :title="impressions.yesterday_diff + '% Higher than two days ago'"> 
                                    {{ impressions.yesterday_diff }}% &nbsp;<fa-icon :icon="['fas', 'chevron-up']" class="w-4 h-4" />&nbsp;
                                </div>
                                <div v-if="impressions.yesterday_diff < 0" class="report-box-indicator bg-theme-6 tooltip cursor-pointer"
                                    :title="impressions.yesterday_diff + '% Lower than two days ago'"> 
                                    {{ impressions.yesterday_diff }}% &nbsp;<fa-icon :icon="['fas', 'chevron-down']" class="w-4 h-4" />&nbsp;
                                </div>
                            </div>
                        </div>
                        <div class="text-xl font-bold leading-8 mt-1">
                            {{ impressions.yesterday | withComma }}
                            <span v-if="isLoading || impressions.yesterday == null">0</span>
                            <small class="text-base text-gray-600 ml-1">IMP</small>
                        </div>
                        <div class="text-base text-gray-600 mt-1">Yesterday</div>
                    </div>
                </div>
            </div>
            <div class="col-span-10 sm:col-span-5 md:col-span-4 lg:col-span-3 xl:col-span-2 intro-x">
                <div class="">
                    <div class="p-3 background3 background-item">
                        <div class="flex">
                            <fa-icon :icon="['fas', 'chart-bar']" size="2x" class="report-box__icon text-theme-12" />
                            <div class="ml-auto">
                                <div v-if="impressions.seven_days_diff > 0" class="report-box-indicator bg-theme-9 tooltip cursor-pointer"
                                    :title="impressions.seven_days_diff + '% Higher than two weeks ago'"> 
                                    {{ impressions.seven_days_diff }}% &nbsp;<fa-icon :icon="['fas', 'chevron-up']" class="w-4 h-4" />&nbsp;
                                </div>
                                <div v-if="impressions.seven_days_diff < 0" class="report-box-indicator bg-theme-6 tooltip cursor-pointer"
                                    :title="impressions.seven_days_diff + '% Lower than two weeks ago'"> 
                                    {{ impressions.seven_days_diff }}% &nbsp;<fa-icon :icon="['fas', 'chevron-down']" class="w-4 h-4" />&nbsp;
                                </div>
                            </div>
                        </div>
                        <div class="text-xl font-bold leading-8 mt-1">
                            {{ impressions.seven_days | withComma }}
                            <span v-if="isLoading || impressions.seven_days == null">0</span>
                            <small class="text-base text-gray-600 ml-1">IMP</small>
                        </div>
                        <div class="text-base text-gray-600 mt-1">7 days</div>
                    </div>
                </div>
            </div>
            <div class="col-span-10 sm:col-span-5 md:col-span-4 lg:col-span-3 xl:col-span-2 intro-x">
                <div class="">
                    <div class=" p-3 background4 background-item">
                        <div class="flex">
                            <fa-icon :icon="['fas', 'chart-bar']" size="2x" class="report-box__icon text-theme-12" />
                            <div class="ml-auto">
                                <div v-if="impressions.current_month_diff > 0" class="report-box-indicator bg-theme-9 tooltip cursor-pointer"
                                    :title="impressions.current_month_diff + '% Higher than month ago'"> 
                                    {{ impressions.current_month_diff }}% &nbsp;<fa-icon :icon="['fas', 'chevron-up']" class="w-4 h-4" />&nbsp;
                                </div>
                                <div v-if="impressions.current_month_diff < 0" class="report-box-indicator bg-theme-6 tooltip cursor-pointer"
                                    :title="impressions.current_month_diff + '% Lower than month ago'"> 
                                    {{ impressions.current_month_diff }}% &nbsp;<fa-icon :icon="['fas', 'chevron-down']" class="w-4 h-4" />&nbsp;
                                </div>
                            </div>
                        </div>
                        <div class="text-xl font-bold leading-8 mt-1">
                            {{ impressions.current_month | withComma }}
                            <span v-if="isLoading || impressions.current_month == null">0</span>
                            <small class="text-base text-gray-600 ml-1">IMP</small>
                        </div>
                        <div class="text-base text-gray-600 mt-1">This Month</div>
                    </div>
                </div>
            </div>
            <div class="col-span-10 sm:col-span-5 md:col-span-4 lg:col-span-3 xl:col-span-2 intro-x">
                <div class="">
                    <div class=" p-3 background5 background-item">
                        <div class="flex">
                            <fa-icon :icon="['fas', 'chart-bar']" size="2x" class="report-box__icon text-theme-9" />
                            <div class="ml-auto">
                                <div v-if="impressions.last_month_diff > 0" class="report-box-indicator bg-theme-9 tooltip cursor-pointer"
                                    :title="impressions.last_month_diff + '% Higher than two months ago'"> 
                                    {{ impressions.last_month_diff }}% &nbsp;<fa-icon :icon="['fas', 'chevron-up']" class="w-4 h-4" />&nbsp;
                                </div>
                                <div v-if="impressions.last_month_diff < 0" class="report-box-indicator bg-theme-6 tooltip cursor-pointer"
                                    :title="impressions.last_month_diff + '% Lower than two months ago'"> 
                                    {{ impressions.last_month_diff }}% &nbsp;<fa-icon :icon="['fas', 'chevron-down']" class="w-4 h-4" />&nbsp;
                                </div>
                            </div>
                        </div>
                        <div class="text-xl font-bold leading-8 mt-1">
                            {{ impressions.last_month | withComma }}
                            <span v-if="isLoading || impressions.last_month == null">0</span>
                            <small class="text-base text-gray-600 ml-1">IMP</small>
                        </div>
                        <div class="text-base text-gray-600 mt-1">Last Month</div>
                    </div>
                </div>
            </div>            
        </div>
    </div>
</template>

<script>

export default {
    props: {
        impressions: {
            required: false,
            type: Object | Array
        },
        isLoading: {
            required: true,
            type: Boolean
        },
    },
    data() {
        return {
        }
    },
    created() {
    },
    methods: {
    },
    watch: {
        impressions: {
            handler: function() {
                this.$feather.replace()
            },
            deep: true
        }
    }
}
</script>
<style scoped>
.info-box-icon p {
    margin-top: 16px;
    font-size: 17px;
}
.background1{
    background-image: linear-gradient(to right,#2c3e53,#263544 );
    border-bottom-left-radius: .5rem;
    border-top-left-radius: .5rem;
}
.background2{
    background-image: linear-gradient(to right,#263544,#1E2A36);
}
.background3{
    background-image: linear-gradient(to right,#1E2A36,#131B22);
}
.background4{
    background-image: linear-gradient(to right,#131B22,#0F161B);
}
.background5{
    background-image: linear-gradient(to right,#080B0E ,#000000 );
    border-bottom-right-radius: .5rem;
    border-top-right-radius: .5rem;
}
.background-item{
    background-color:transparent;
    color: whitesmoke;
    box-shadow: 0px 3px 20px #0000000b;
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
    /* border-radius: 0.375rem; */
    position: relative;
}
.report-box-indicator{
    padding-top: 3px;
    padding-bottom: 3px;
    display: flex;
    border-radius: 9999px;
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity));
    font-size: 0.75rem;
    padding-left: 0.5rem;
    padding-right: 0.25rem;
    align-items: center;
    font-weight: 500;
}
.sm-text{
    color: #240000;
}
@media (max-width: 639px) { 
 .background1{
    border-bottom-left-radius: 0rem;
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
    }
.background5{
    border-bottom-right-radius: .5rem;
    border-top-right-radius: 0rem;
    border-bottom-left-radius: .5rem;
    }   
}
</style>