<template>
  <div v-if="isAuthenticated" class="overflow-hidden">
    <SidebarMobile />
    <div class="flex">
      <Sidebar :user="userData" />
      <!-- BEGIN: Content -->
      <div class="content my-4 mr-4 ml-4 md:ml-0">
        <Nav :user="userData" class="top-bar" />
          <!-- Main content -->
            <router-view  />
          <!-- built files will be auto injected -->
      </div>
    </div>
    <!-- ./flex -->
    <Footer />
  </div>
</template>
  
<script>
import Sidebar from "@/components/core/Sidebar"
import SidebarMobile from "@/components/core/SidebarMobile"
import Nav from "@/components/core/Nav"
import Footer from "@/components/core/Footer"

export default {
  name: 'home',
  data() {
    return {
      user: {},
    }
  },
  components: {
    Sidebar,
    SidebarMobile,
    Nav,
    Footer,
  },
  created() {
  },
  methods: {

  },
  computed: {
    isAuthenticated() {
      if(this.$store.getters.isAuthenticated === true) {
        return true
      }
      this.$router.push('/login')
      return false
    },
    userData() {
      return this.$store.getters.user
    },
    currentRouteName() {
      return this.$route.name;
    }
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  }
}
</script>
