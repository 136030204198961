<template>
    <div class="mt-8 xl:w-2/3 mx-auto">
        <div class="rounded-md shadow-md bg-white dark:bg-dark-3 p-5 xl:p-12">
            <h1 class="intro-x font-bold text-2xl xl:text-3xl text-left border-b dark:border-dark-5 pb-3">New user</h1>
            <div class="intro-x mt-8 grid grid-cols-12">
                <div class="col-span-12">
                    <float-label>
                        <input type="text" v-model="user.first_name" class="intro-x login__input input input--lg border border-gray-300 block w-full " placeholder="First Name" required>
                    </float-label>
                </div>
                <div class="col-span-12  mt-4">
                    <float-label>
                        <input type="text" v-model="user.last_name" class="intro-x login__input input input--lg border border-gray-300 block w-full " placeholder="Last Name" required>
                    </float-label>
                </div>
                <div class="col-span-12  mt-4">
                    <float-label>
                        <input type="userName" v-model="user.username" class="intro-x login__input input input--lg border border-gray-300 block w-full " placeholder="Username" required>
                    </float-label>
                </div>
                <div class="col-span-12 mt-4">
                    <float-label>
                        <input type="email" v-model="user.email" class="intro-x login__input input input--lg border border-gray-300 block w-full" placeholder="Email">
                    </float-label>
                </div>
                <div class="col-span-12 mt-4">
                    <float-label>
                        <input type="password" v-model="user.password" class="intro-x login__input input input--lg border border-gray-300 block w-full" placeholder="Password" required>
                    </float-label>
                </div>
                <div class="col-span-12 mt-4">
                    <float-label>
                        <input type="password" v-model="user.password_confirm" class="intro-x login__input input input--lg border border-gray-300 block w-full" placeholder="Password Confirmation" required>
                    </float-label>
                </div>
                <div class="col-span-12 mt-4">
                    <float-label>
                        <input type="phone" v-model="user.profile.phone_number" class="intro-x login__input input input--lg border border-gray-300 block w-full" required placeholder="Phone *">
                    </float-label>
                </div>
            </div>
             <div class="intro-x flex items-center pb-4 mt-4 col-span-12 border-b dark:border-dark-5 ">
                <label class="cursor-pointer select-none px-4 text-sm sm:text-lg"  :class="{'text-theme-9': user.is_active == 'true'}">
                    <input type="radio" value="true" v-model="user.is_active"> Active
                </label>
                <label class="cursor-pointer select-none px-4 text-sm sm:text-lg" :class="{'text-theme-6': user.is_active == 'false'}">
                    <input type="radio" value="false" v-model="user.is_active"> Suspended
                </label>
            </div>

            <div class="col-span-12 mt-4">
                <button @click.prevent="add()" class="intro-x button button--lg text-white bg-theme-1 w-64">Add</button>
            </div>
        </div>
    </div>
</template>

<script>
import { RepositoryFactory } from '@/services/repositories/AuthAPI/RepositoryFactory'
const UsersRepo = RepositoryFactory.get('users')

export default {
    components: {
    },
    data() {
        return {
            user: {
                profile: {}
            },
            messages: [],
            errors: [],
        }
    },
    methods: {
        clear: function() {
            this.user = {}
        },
        add: function() {
            this.errors = []
            this.messages = []
            this.$notify({
                group: 'ops',
                clean: true,
            })

            UsersRepo.post(this.user).then(result => {
                this.$notify({
                    group: 'ops',
                    type: 'success',
                    title: 'Operations Success',
                    text: 'User created successful.'
                })

                this.$router.push( {name: 'user-info', params: {id: result.data.id}} )
            }).catch(err => {
                this.$notify({
                    group: 'ops',
                    type: 'error',
                    title: 'Operations failed.',
                    text: err
                })
                this.errors.push( err.response.data )
            })
        },
    },
    computed: {

    },
    watch: {

    }
}
</script>

<style scoped>

</style>