function statisticsToGraphData(data, l14days=false, yesterday=false, reverse=false) {
    /**
     * website:
     * name:
     * id:
     * url:
     * 30: date[1,2,3,4,5,6], cpm[1,2,3,4,5,6], impressions[1,2,3,4,5,6], revenue[1,2,3,4,5,6], loop the data get value
     * 14: date[1,2,3,4,5,6], cpm[1,2,3,4,5,6], impressions[1,2,3,4,5,6], revenue[1,2,3,4,5,6], loop the data get value
     * 01: date[1,2,3,4,5,6], cpm[1,2,3,4,5,6], impressions[1,2,3,4,5,6], revenue[1,2,3,4,5,6], loop the data get value
     */
    var last30days = {}

    var date = []
    var cpm = []
    var impressions = []
    var revenue = []
    var counter = 0

    data.forEach(function (statistic, key) {
        date.push(statistic.date);
        Object.entries(statistic).forEach(function (stats) {
            if(stats[0] === 'cpm') {
                cpm.push(stats[1]);
            }
            if(stats[0] === 'impressions') {
                impressions.push(stats[1]);
            }
            if(stats[0] === 'revenue') {
                revenue.push(stats[1]);
            }
        });
        counter++;
    });
    last30days = {
        "date": reverse? date.reverse() : date,
        "cpm": reverse? cpm.reverse() : cpm,
        "impressions": reverse? impressions.reverse() : impressions,
        "revenue": reverse? revenue.reverse() : revenue
    };

        if(l14days) {
        var data14days = {}
        var cutRecords = 14
        var length = date.length - cutRecords

        data14days = {
            "date": date.slice(length),
            "cpm": cpm.slice(length),
            "impressions": impressions.slice(length),
            "revenue": revenue.slice(length),
        }

        if(length < 0) {
            data14days = {
                "date": date,
                "cpm": cpm,
                "impressions": impressions,
                "revenue": revenue,
            }
        }
 
        return data14days
    } 
    
    else if(yesterday) {
        yesterday = {
            "date": date.slice(29, 30)[0],
            "cpm": cpm.slice(29, 30)[0],
            "impressions": impressions.slice(29, 30)[0],
            "revenue": revenue.slice(29, 30)[0],
        }
        return yesterday

    }

    return last30days
}

export default statisticsToGraphData