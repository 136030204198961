<template>
    <canvas id="report-line-chart" height="400" class="mt-6"></canvas>
</template>

<script>
import statisticsToGraphData from './data'
import 'chartjs-adapter-date-fns'
import { enUS } from 'date-fns/locale'

export default {
    name: 'ChartJS',
    props: {
        data: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            chart: false
        }
    },
    created() {

    },
    mounted: function() {
        this.init()
    },
    methods: {
        init: function() {
            let ctx = document.getElementById('report-line-chart').getContext("2d")
            this.currenGraph(ctx)
        },
        currenGraph: function(element) {
            let last30DaysStatistics = statisticsToGraphData(this.data, false, false, true)
            var labels = last30DaysStatistics.date
            var dataSet = [{
                yAxisID: 'yAxes',
                label: "Impressions",
                borderColor: '#2caece',
                backgroundColor: 'RGBA(38, 53, 86, 0.22)',
                fill: false,
                data: last30DaysStatistics.impressions,
                lineTension: 0.4
            }, {
                yAxisID: 'yAxes2',
                label: "Revenue",
                borderColor: '#21bb9b',
                backgroundColor: 'RGBA(33,187,155,0.22)',
                fill: false,
                data: last30DaysStatistics.revenue,
                lineTension: 0.4
            }
        ];
            var options = {
                responsive: true,
                interaction: {
                    mode: 'index',
                    intersect: false,
                },
                maintainAspectRatio: false,
                stacked: true,
                title:{
                    display: false,
                    text:'Last 30 Days'
                },
                scales: {
                    yAxes: {
                        type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                        display: true,
                        title: "Impressions",
                        position: "left",
                        id: "yAxes",
                        beginAtZero: true,
                    }, 
                    yAxes2: {
                        type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                        display: true,
                        title: "Revenue",
                        position: "right",
                        id: "yAxes2",
                        grid: {
                            drawOnChartArea: false, // only want the grid lines for one axis to show up
                        },
                        beginAtZero: true,
                    }, 
                    xAxis: {
                        display: true,
                        id: "xAxis",
                        type: 'time',
                        time: {
                            unit: 'day'
                        },
                        gridLines: {
                            display: true
                        },
                        adapters: { 
                            date: {
                                locale: enUS, 
                            },
                        }
                    }
                }
            };
            this.chart = new this.$chart(element, {
                type: 'line',
                data: {
                    labels: labels,
                    datasets: dataSet
                },
                options: options
            })
        }
    },
    watch: {
        data: function(data) {
            if(this.chart)
                this.chart.destroy()
            this.init()
        }
    }
}
</script>

<style scoped>

</style>