import UsersRepo from './../AuthAPI/UsersRepo'
import WebsitesRepository from './WebsitesRepo'
import GroupsStatistics from './GroupsStatisticsRepo'
import WebsitesStatistics from './WebsitesStatisticsRepo'
import WhitelistRepo from './WhitelistRepo'
import SystemRepo from './SystemRepo'
import SharesRepo from './SharesRepo'
import GroupsRepo from './GroupsRepo'
import GroupsTypesRepo from './GroupsTypesRepo'

import TagsRepo from './TagsRepo'
import AdsRepo from './AdsRepo'

import AssignedRepo from './../AuthAPI/AssignedRepo';

import WalletsRepo from './Wallets/WalletsRepo'
import InvoicesRepo from './Wallets/InvoicesRepo'
import AccountsRepo from './Wallets/AccountsRepo'

const repositories = {
    websites: WebsitesRepository,
    groups_statistics: GroupsStatistics,
    websites_statistics: WebsitesStatistics,
    users: UsersRepo,
    
    tags: TagsRepo,
    ads: AdsRepo,

    whitelist: WhitelistRepo,

    system: SystemRepo,
    shares: SharesRepo,
    groups: GroupsRepo,
    groups_types: GroupsTypesRepo,

    assignments: AssignedRepo,

    wallets: WalletsRepo,
    invoices: InvoicesRepo,
    account_wallets: AccountsRepo,
}

export const RepositoryFactory = {
    get: name => repositories[name],
};
